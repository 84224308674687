/* ADMIN */
.second {
  background-color:rgb(60, 4, 87) !important;
}

.fastest {
  font-weight: 700;
  background-color:rgb(108, 0, 158) !important;
  color: #f2f2f2;
}
/* END */

body {
  background: radial-gradient(at top, #353b44 -10%, #252932 60%);
  min-height: 100vh;
}

/* MAIN */
.App {
  text-align: center;
  color: white;
}

li {
  list-style: none;
}

.table-heading {
  text-align: center;
}

.standings {
  /* font-size: 1rem; */
  flex: 1;
}

.sessions-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.session-list {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  max-height: 80vh;
  overflow-y: auto;
}

.list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-radius: 4px;
}
.list-item:hover {
  background: #353B58;
}

.button-wrapper {
  display: flex;
}

button {
  margin: 0.5vmin;
}

* body {
  font-family: "Titillium Web";
  color:#212529;
}

.pageTitle{
  font-size: 3em;
}

/* RESULT TABLE */
table {
  border-collapse: collapse;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.track {
  text-align: center;
  font-size: 3em;
}

.timerContainer {
  margin-top: -80px;
  margin-bottom: -60px;
}

.timer {
  text-align: center;
  font-size: 3em;
  margin-top: -65px;
}

th, td {
  text-align: left;
  padding: 8px;
}

.up {
  position:absolute;
  margin-top: 10px;
  margin-left: 5px;
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid lime;
}

.down {
  position:absolute;
  margin-top: 10px;
  margin-left: 5px;
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid red;
}

.pending-dot {
  padding-left: 20px;
}

.dot {
  position:absolute;
  height: 5px;
  width: 5px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  animation: blinker 1s linear infinite;
}

.top-10 {
  margin-top: 10px;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.standings-row {
  border-radius: 4px;
  text-align: center;
}

.standings-row:nth-child(even) {
  background: #353B58;
}

.item{
  background-size: cover;
  background-position: center center;  
  --squareSize: 13vw;
  width: var(--squareSize);
  height: var(--squareSize);
}

.squareGrid{
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 55vw;
}

.squareGrid a{
  font-size: 1vw;
  font-weight: bolder;
}

/* BIG SCREEN */
@media (min-width: 98em) {
  .squareGrid{
    max-width: 35vw;
  }
  
  .item{
    --squareSize: 8vw;
    width: var(--squareSize);
    height: var(--squareSize);
  }
}

/* MOBILE */
@media (max-width: 50em) {
  .squareGrid{
    max-width: 90%;
  }
  
  .item{
    --squareSize: 20vw;
    width: var(--squareSize);
    height: var(--squareSize);
  }
}

.trackTabs{
  display: inline-flex;
}

.trackTabTitle{
  font-size: 3em;
  color: whitesmoke;
}

a.trackTabTitle.nav-link:hover{
  color: #af01ff;
}

a.trackTabTitle.nav-link.active{
  color: white;
  background-color: transparent;
}

.trackSquareTitle{
  font-size: 2em;
  margin:0;
}

.trackSquare div{
  filter: brightness(0) invert(1);
}


a.trackSquare:hover{
  color: #af01ff;
}

.results-table,
.queue-table,
.queue-rows
.rows {
  display: flex;
  flex-direction: column;
}
.rows {
  max-height: 80vh;
  overflow-y: auto;
}
.queue-rows {
  max-height: 40vh;
  overflow-y: auto;
}
.headings,
.columns,
.row{
  display: flex;
  margin-right: 0%;
  margin-left: 0%;
}
.row:nth-child(odd) {
  background: #353B58;
}
.column:nth-child(odd),
.speedwaydata-table .kart-label:nth-child(odd){
  background: #353B58;
}
.heading,
.cell{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  flex: 1;
}
.compact-cell{
  align-items: center;
  justify-content: center;
  flex: 1;
}
.column {
  flex: 1;
}
.heading {
  font-weight: 600;
}
/* MOBILE */
@media (max-width: 50em) {
  .trackTabTitle{
    font-size: 2em;
  }
  
  .trackSquareTitle{
    font-size: 5em;
    padding: 0;
    margin: 0;
  }
}

a {
  text-decoration: none;
}

/* Admin table data */
.speedwaydata-table {
  display: flex;
}
.speedwaydata-table .kart-list {
  width: 15%;
  cursor: pointer;
}
.speedwaydata-table .kart-list .kart-label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}
.speedwaydata-table .kart-list .kart-label:hover,
.speedwaydata-table .kart-list .kart-label.active {
  background: #af01ff;
}
.speedwaydata-table .kart-data {
  width: 45%;
}
.speedwaydata-table .kart-data .lap-times .lap-time {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  flex: 1;
}
.speedwaydata-table .kart-data .lap-times .lap-time .time {
  width: 60%;
}
.speedwaydata-table .kart-data .lap-times .lap-time:nth-child(even) {
  background: #353B58;
}
.speedwaydata-table .kart-data .action-holder {
  display: flex;
  flex-direction: column;
}
.speedwaydata-table .kart-data .action-holder button {
  width: 100%;
}
@media screen and (max-width: 900px) {
  .speedwaydata-table .kart-list {
    width: 40%;
  }
  .speedwaydata-table .kart-list .kart-label,
  .speedwaydata-table .kart-data .lap-times .lap-time {
    height: 45px;
  }
  .speedwaydata-table .kart-data {
    width: 60%;
    margin-right: 10px;
  }
}
